import React, { useCallback, useState } from "react";
import { Link, useNavigate, withRouter } from "react-router-dom";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import "./Form.css";
import { UseOnSubmit } from "../../hooks/useOnSubmit";
import FormikController from "../../utils/FormikController";
import { FileUploader } from "react-drag-drop-files";
import DefaultButton from "../buttons/DefaultButton";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Slide, Zoom, Flip, Bounce } from "react-toastify";
import Spinner from "../spinner/Spinner";

function EditFunnelForm({ idFunnel, theModal, reload }) {
  const [file, setFile] = useState(null);
  const navigate = useNavigate();
  const [disabled, setDisabled] = useState(false);
  const [loader, setLoader] = useState(false);

  const onExit = (count) => {
    if (count === 2) {
      window.localStorage.setItem("view_dashboard", true);
      // setStepEnabled(false);
    }
  };

  const onSubmit = async (values) => {
    let formData = new FormData();
    formData.append("Gift", file);
    formData.append("NameProduct", values.NameProduct);
    formData.append("UrlProduct", values.UrlProduct);
    formData.append("Type", file.type);
    formData.append("UrlRoute", "");
    formData.append("Description", values.Description);

    try {
      setLoader(true);

      await UseOnSubmit({
        axiosParams: {
          method: "PUT",
          url: `/page/product/${idFunnel}`,
          data: formData,
        },
        process: true,
        txtSuccess: "Funnel Actiualizado",
        txtWrong: "Error al actualizar el Funnel",

        functionSuccess: (values) => {
          //   setTimeout(function () {
          reload();
          setLoader(false);
          setDisabled(false);
          theModal(false);

          // navigate("/admin/pages/" + values.id);
          //   }, 1000);
        },
        functionWrong: () => {
          setLoader(false);

          setDisabled(false);
        },
      });
    } catch (e) {
      console.log(e);
    }
  };
  const handleChange = (file) => {
    setFile(file);
  };

  const initialValues = {
    NameProduct: "",
    UrlProduct: "",
    Description: "",

    Type: "pdf de regalo",
    UrlRoute: "funnel/",
  };
  const validationSchema = Yup.object({
    NameProduct: Yup.string().required("Requerido"),
    UrlProduct: Yup.string().required("Requerido"),
    Description: Yup.string().required("Requerido"),
  });

  const options = {
    showProgress: true,
    showBullets: true,
    exitOnOverlayClick: true,
    exitOnEsc: true,
    nextLabel: "Siguiente",
    prevLabel: "Anterior",
    // skipLabel: "Salir",
    hidePrev: true,
    doneLabel: "Done",
    overlayOpacity: 0.5,
    overlayColor: "#000",
    showStepNumbers: true,
    keyboardNavigation: true,
    scrollToElement: true,
    helperElementPadding: 10,
    showButtons: true,
    doneLabel: "Fin",
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {/* <Steps
          enabled={stepEnabled}
          steps={[
            {
              title: "Bienvenido al funnel de midas!!",
              intro:
                "Crea una página para promocionar tus ventas y compártela con tu audiencia..",
              // tooltipClass: "welcomeStep",
            },
            {
              element: "#createFunnel",
              title: "Con este botón podrás crear tu pagina",
              // intro: "You can jump to the User Settings page from here.",
              // position: "right",
            },
            {
              element: "#closeSession",
              title: "Aquí podrás cerrar sesión.",
              // position: "right",
            },
          ]}
          initialStep={0}
          onExit={onExit}
          options={options}
        /> */}
        {(formik) => (
          <Form>
            <div className="flex_column_form">
              <FormikController
                control="input"
                type="text"
                label="Nombre del Producto"
                name="NameProduct"
              />
              <FormikController
                control="input"
                type="text"
                label="Url del producto"
                name="UrlProduct"
              />

              <div id="FileUpload" className="">
                <FormikController
                  control="input"
                  type="text"
                  label="Descripcion"
                  name="Description"
                />
                <FileUploader
                  name="Gift"
                  handleChange={handleChange}
                  types={["PDF"]}
                />
              </div>
              <DefaultButton
                id="button-submit"
                type="submit"
                textBtn={"Editar"}
                disabled={disabled}
              />
            </div>
            {loader && <Spinner />}
          </Form>
        )}
      </Formik>
    </>
  );
}

export default EditFunnelForm;
