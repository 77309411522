import React, { useCallback, useState } from "react";
import { Link, useNavigate, withRouter } from "react-router-dom";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import "./Form.css";
import { UseOnSubmit } from "../../hooks/useOnSubmit";
import FormikController from "../../utils/FormikController";
import { FileUploader } from "react-drag-drop-files";
import DefaultButton from "../buttons/DefaultButton";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Slide, Zoom, Flip, Bounce } from "react-toastify";

function ContactForm({ idPage }) {
  const navigate = useNavigate();

  const onSubmit = async (values) => {
    let formData = new FormData();
    formData.append("Name", values.Name);
    formData.append("Email", values.Email);
    formData.append("PageId", idPage);

    try {
      await UseOnSubmit({
        axiosParams: {
          method: "POST",
          url: "/contact",
          data: formData,
        },
        process: true,
        txtSuccess: "Se le enviara su regalo al correo",
        txtWrong: "Hubo un error intentelo de nuevo",

        functionSuccess: (values) => {
          //   setTimeout(function () {
          //     navigate("/admin/pages/" + values.id);
          //   }, 1000);
        },
      });
    } catch (e) {
      console.log(e);
    }
  };

  const initialValues = {
    Name: "",
    Email: "",
  };
  const validationSchema = Yup.object({
    Name: Yup.string().required("Requerido"),
    Email: Yup.string().required("Requerido"),
  });

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {(formik) => (
        <Form>
          <div className="flex_column_form">
            <FormikController
              control="input"
              type="text"
              label="Nombre"
              name="Name"
            />
            <FormikController
              control="input"
              type="text"
              label="Correo"
              name="Email"
            />

            <DefaultButton
              id="button-submit"
              type="submit"
              textBtn={"Quiero mi regalo"}
            />
          </div>
        </Form>
      )}
    </Formik>
  );
}

export default ContactForm;
