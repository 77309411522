import React, { useCallback, useState } from "react";
import { Link, useNavigate, withRouter } from "react-router-dom";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import "./Form.css";
import { UseOnSubmit } from "../../hooks/useOnSubmit";
import FormikController from "../../utils/FormikController";
import { FileUploader } from "react-drag-drop-files";
import DefaultButton from "../buttons/DefaultButton";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Slide, Zoom, Flip, Bounce } from "react-toastify";
import Spinner from "../spinner/Spinner";

function FunnelForm({ idTemplate }) {
  const [file, setFile] = useState(null);
  const navigate = useNavigate();
  const [disabled, setDisabled] = useState(false);
  const [loader, setLoader] = useState(false);

  const onSubmit = async (values) => {
    let formData = new FormData();
    formData.append("Gift", file);
    formData.append("NameProduct", values.NameProduct);
    formData.append("UrlProduct", values.UrlProduct);
    formData.append("Type", file.type);
    formData.append("UrlRoute", "");
    formData.append("TemplateId", idTemplate);

    try {
      setLoader(true);

      await UseOnSubmit({
        axiosParams: {
          method: "POST",
          url: "/page/createPage",
          data: formData,
        },
        process: true,
        txtSuccess: "Funnel Creado",
        txtWrong: "Error al crear el Funnel",

        functionSuccess: (values) => {
          setTimeout(function () {
            setLoader(false);
            setDisabled(false);

            navigate("/admin/pages/" + values.id);
          }, 1000);
        },
        functionWrong: () => {
          setLoader(false);

          setDisabled(false);
        },
      });
    } catch (e) {
      console.log(e);
    }
  };
  const handleChange = (file) => {
    setFile(file);
  };

  const initialValues = {
    NameProduct: "",
    UrlProduct: "",
    Type: "pdf de regalo",
    UrlRoute: "route/test",
  };
  const validationSchema = Yup.object({
    NameProduct: Yup.string().required("Required"),
    UrlProduct: Yup.string().required("Required"),
  });

  

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {(formik) => (
        <Form>
          <div className="flex_column_form">
            <FormikController
              control="input"
              type="text"
              label="Nombre del Producto"
              name="NameProduct"
            />
            <FormikController
              control="input"
              type="text"
              label="Url del producto"
              name="UrlProduct"
            />
            <p className="label-input">Regalo</p>

            <div className="" id="FileUpload">
              <FileUploader
                name="Gift"
                handleChange={handleChange}
                types={["PDF"]}
              />
            </div>

            <DefaultButton
              id="button-submit"
              type="submit"
              textBtn={"Crear"}
              disabled={disabled}
            />
          </div>
          {loader && <Spinner />}
        </Form>
      )}
    </Formik>
  );
}

export default FunnelForm;
