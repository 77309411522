import { useState, useEffect } from "react";
import axios from "axios";
import config from "../config.json";

axios.defaults.baseURL = config.requestURL;

axios.interceptors.request.use(
  (config) => {
    const token_seguridad = window.localStorage.getItem("token_seguridad");
    if (token_seguridad) {
      config.headers.Authorization = "Bearer " + token_seguridad;

      return config;
    }
  },
  (error) => {
    return Promise.reject(error);
  }
);
const useAxiosAuth = (axiosParams, load = true, attribute = "") => {
  const [response, setResponse] = useState(undefined);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true);
  const [header, setheader] = useState(undefined);

  const fetchData = async () => {
    try {
      const result = axiosParams?.url ? await axios.request(axiosParams) : null;
      if (axiosParams?.url) {
        setResponse(attribute ? result.data[attribute] : result.data);
      } else {
        setResponse(null);
      }

      setError(result.status);
      setheader(result.headers);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };
  function reload() {
    if (load) {
      setLoading(true);
    }
    fetchData();
  }

  useEffect(() => {
    const ac = new AbortController();
    fetchData();
    return () => ac.abort();
  }, []);

  return { response, error, loading, reload, header };
};
export default useAxiosAuth;
