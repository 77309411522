import {
  BrowserRouter,
  Routes,
  Route,
  useParams,
  useLocation,
  HashRouter,
} from "react-router-dom";
import "./App.css";
import React, { useState } from "react";
import Login from "./views/admin/login/Login";
import Dashboard from "./views/admin/dashboard/Dashboard";
import Editor from "./views/admin/editor/Editor";
import DashboardAdmin from "./views/admin/dashboard/superadmin/DashboardAdmin";
import { useAxiosAuth, useAxios } from "./hooks";
import { Style } from "react-style-tag";
import { UseOnSubmit } from "./hooks/useOnSubmit";
import ContactForm from "./components/form/ContactForm";
import { useEffect } from "react";
import Thanks from "./views/client/Tkanks";
import GuardedRoute from "./components/guard/Guard";
import PrivateRoute from "./components/guard/Guard";
import Statistic from "./views/admin/dashboard/stadistics/Statistics";
import Home from "../src/views/home/Home";
import GenerateResource from "./views/GenerateResource/GenerateResource";
function App() {
  useEffect(() => {
    document.title = "Midas";
  });

  const Page = () => {
        const { id } = useParams();
    console.log(id);

    // const { response: responseContact, loading: loadingContact } = useAxiosAuth({
    //   method: "POST",
    //   url: `/contact`,
    // });

    const { response, loading } = useAxios({
      method: "GET",
      url: `/page/urlpage/${id}`,
    });
    const css = loading ? "" : response.css;

    // let pageHTml = response.html.replace("$$$$$$PAGE$$$$$$$$$", id)

    // console.log(page);

    return loading ? (
      <></>
    ) : (
      <>
        <div
          dangerouslySetInnerHTML={{
            __html: response.html.replace("$$$$$$$$PAGE$$$$$$$$$", id),
          }}
        />
        <style>{css}</style>
        {/* <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
          <div style={{ width: "30em" }}>
            <ContactForm idPage={id} />

          </div>
        </div> */}
      </>
    );
  };

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/">
          <Route index element={<Login />} />
          <Route
            path=":name"
            element={
              // <PrivateRoute>
              <Page />
              // </PrivateRoute>
            }
          />
          <Route
            path=":name/:id"
            element={
              // <PrivateRoute>
              <Page />
              // </PrivateRoute>
            }
          />
        </Route>

        <Route path="/thanks/:id" element={<Thanks />} />

        <Route path="/admin/login" element={<Login />} />

        <Route
          path="/admin/dashboard"
          element={
            <PrivateRoute>
              <Dashboard />
            </PrivateRoute>
          }
        />
        {/* <Route path="/admin/dashboard" element={<Dashboard />} /> */}
        <Route
          path="/admin/pages/:id"
          element={
            <PrivateRoute>
              <Editor />
            </PrivateRoute>
          }
        />
        <Route
          path="/admin/statistic/:id"
          element={
            <PrivateRoute>
              <Statistic />
            </PrivateRoute>
          }
        />
        {/* <Route path="/admin/pages/:id" element={<Editor />}></Route> */}
        <Route
          path="/admin/dashboardadmin"
          element={
            <PrivateRoute>
              <DashboardAdmin />
            </PrivateRoute>
          }
        />
        <Route
          path="/Home"
          element={
            <PrivateRoute>
              <Home />
            </PrivateRoute>
          }
        />
        <Route
          path="/GenerateResource"
          element={
            <PrivateRoute>
              <GenerateResource />
            </PrivateRoute>
          }
        />
        {/* <Route path="/admin/dashboardadmin" element={<DashboardAdmin />}></Route> */}

        {/* <Route path="/:name/:id" element={<Page />} /> */}
      </Routes>
    </BrowserRouter>
  );
}

export default App;
