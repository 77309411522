import React, { Component } from "react";
import "./Login.css";
import logo from "../../../assets/images/funnelLogo.png" // Tell webpack this JS file uses this image
import LoginForm from "../../../components/form/LoginForm";
import { useLocation } from "react-router-dom";


const Login = () => {
  return (
    <div className="layoutFull">
      <div className="layoutLogin">
        <div className="back-im">
          
        </div>
        <div className="login">
          <img src={logo} alt="Logo" className="logo" />  
          <LoginForm />
        </div>
      </div>
      <div className="footer">

      </div>
    </div>
  );
};

export default Login;
