import React, { Component, useState } from "react";
import "./DashboardAdmin.css";
import logo from "../../../../assets/images/logo.png"; // Tell webpack this JS file uses this image
import DefaultButton from "../../../../components/buttons/DefaultButton";
import Table from "../../../../components/table/Table";
import ModalForm from "../../../../components/modal/Modal";
import { useAxiosAuth } from "../../../../hooks";
import { Edit, LogOut, User, X } from "react-feather";
import Spinner from "../../../../components/spinner/Spinner";
import UserForm from "../../../../components/form/Userform";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Slide, Zoom, Flip, Bounce } from "react-toastify";
import { Popover } from "react-tiny-popover";
import { useNavigate } from "react-router-dom";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css"; // optional

const DashboardAdmin = () => {
  const username = window.localStorage.getItem("username");

  const [modal, setModal] = useState(false);
  const [modalUpdate, setModalUpdate] = useState(false);
  const [popover, setPopover] = useState(false);

  const [url, setUrl] = useState("");
  const [item, setItem] = useState({});

  const { response, loading , reload} = useAxiosAuth({
    method: "GET",
    url: "/user",
  });
  const nodes = loading ? [] : response;
  const navigate = useNavigate();

  const data = { nodes };

  const COLUMNS = [
    {
      label: "Usuario",
      renderCell: (item) =>
        item.name + " " + item.fatherLastName + " " + item.motherLastName,
    },
    { label: "Correo", renderCell: (item) => item.email },

    {
      label: "Nombre de usuario",
      renderCell: (item) => item.username,
    },

    {
      label: "Editar",
      renderCell: (item) => (
        <Tippy content="Editar">

        <div
          onClick={async () => {
            setUrl("/user/" + item.id);
            // console.log(item);
            setModalUpdate(true);
            setItem(item);
          }}
          className="button_action"
        >
          <Edit />
        </div>
        </Tippy>
      ),
    },
  ];

  return (
    <section className="layoutDashboard">
      <div className="header">
        <div className="logo">
          <img src={logo} alt="Logo" />
          <span>FUNNEL</span>
        </div>
        <Popover
          isOpen={popover}
          positions={["bottom", "top", "left", "right"]} // preferred positions by priority
          content={
            <div
              className="popover"
              onClick={() => {
                navigate("/admin/login");
                window.localStorage.setItem("token_seguridad", "default");
              }}
            >
              Cerrar Sesión <LogOut />
            </div>
          }
        >
          <div
            className="avatar"
            onClick={() => {
              setPopover(!popover);
            }}
          >
            <User />
            {username}
          </div>
        </Popover>
      </div>
      <div className="main">
        <div className="top">
          Usuarios
          <DefaultButton
            onClick={() => setModal(true)}
            textBtn={"Crear Usuario"}
          />
        </div>

        <div className="table">
          {false ? (
            <div className="spinner-center">
              <Spinner />
            </div>
          ) : (
            <Table columns={COLUMNS} data={data} />
          )}
        </div>
        <div className="modal">
          <ModalForm isOpen={modal}>
            <div className="modal-header">
              <X
                className="modal-button-close scale-up-horizontal-center"
                onClick={() => setModal(false)}
              />

              <h2>Crear Usuario</h2>
            </div>

            <UserForm
              url={"/auth/register"}
              txtSuccess={"Usuario Creade"}
              txtWrong={"Fallo al crear"}
              theModal={setModal}
              reload = {reload}
            />
          </ModalForm>
          <ModalForm isOpen={modalUpdate}>
            <div className="modal-header">
              <X
                className="modal-button-close scale-up-horizontal-center"
                onClick={() => setModalUpdate(false)}
              />

              <h2>Actualizar Usuario</h2>
            </div>

            <UserForm
              url={url}
              txtSuccess={"Usuario Actualizado"}
              txtWrong={"Fallo al actualizar"}
              valuesUSer={item}
              textBtn={"Actualizar Usuario"}
              theModal={setModalUpdate}
              reload = {reload}
            />
          </ModalForm>
        </div>
      </div>
      <ToastContainer />
    </section>
  );
};

export default DashboardAdmin;
