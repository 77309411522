import React, { useCallback } from "react";
import { Link, useNavigate, withRouter } from "react-router-dom";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import "./Form.css";
import { UseOnSubmit } from "../../hooks/useOnSubmit";
import FormikController from "../../utils/FormikController";
import DefaultButton from "../buttons/DefaultButton";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Slide, Zoom, Flip, Bounce } from "react-toastify";
import { useState } from "react";
import Spinner from "../spinner/Spinner";

function LoginForm() {
  const [disabled, setDisabled] = useState(false);
  const [loader, setLoader] = useState(false);

  const navigate = useNavigate();
  const onSubmit = async (values) => {
    try {
      setLoader(true);
      window.localStorage.setItem("token_seguridad", "default");
      setDisabled(true);
      await UseOnSubmit({
        auth: false,
        axiosParams: {
          method: "POST",
          url: "/auth/login",
          data: values,
        },
        process: true,
        functionSuccess: (values) => {
          window.localStorage.setItem("token_seguridad", values.token);
          window.localStorage.setItem("username", values.user.name);
          if (values.rols.length > 1) {
            setTimeout(function () {
              setDisabled(false);
              setLoader(false);

              navigate("/admin/dashboardadmin", { replace: true });
            }, 1000);
          } else {
            setTimeout(function () {
              setDisabled(false);
              setLoader(false);

              navigate("/Home", { replace: true });
            }, 1000);
          }
        },
        functionWrong: () => {
          setLoader(false);

          setDisabled(false);
        },
      });
    } catch (e) {
      console.log(e);
    }
  };

  const initialValues = {
    UsernameOrEmail: "",
    Password: "",
  };
  const validationSchema = Yup.object({
    UsernameOrEmail: Yup.string().required("Requerido"),
    Password: Yup.string().required("Requerido"),
  });

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {(formik) => (
        <Form>
          <div className="flex_column_form">
            <div className="emailIcon">
              <FormikController
                control="input"
                type="text"
                label="Email"
                name="UsernameOrEmail"
                icon="fas fa-user"
              />
            </div>
            <FormikController
              control="input"
              type="password"
              label="Password"
              name="Password"
            />
            <DefaultButton
              textBtn={"Ingresar"}
              type="submit"
              disabled={disabled}
            />
          </div>
          <ToastContainer />
          {loader ? <Spinner /> : <></>}
        </Form>
      )}
    </Formik>
  );
}

export default LoginForm;
