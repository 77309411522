export const validate = (data) => {
  if (data === undefined || data === null) {
    return "--";
  }
  return data;
};
export const validateVar = (data) => {
  if (data === undefined || data === null) {
    return "";
  }
  return data;
};

export const validateImg = (img) => {
  if (img === undefined || img === null) {
    return "";
  }
  return img;
};
export const validateArray = (dataArray) => {
  return dataArray ? (dataArray.length !== 0 ? true : false) : false;
};
export const validateStatus = (state) => {
  const status = [200, 201, 202, 203, 204];
  if (status.indexOf(state) > -1) {
    return true;
  }
  return false;
};
export const validateDataArray = (state, status) => {
  // const status = [200, 201];
  if (status.indexOf(state) > -1) {
    return true;
  }
  return false;
};
export const validateInstitution = (institutions, rolUser) => {
  let institution = [];
  if (institutions.length > 0) {
    institutions.forEach((inst) => {
      const result = inst.rols.filter((r) => {
        return r.name === rolUser;
      });
      if (result.length > 0) {
        institution.push({ ...inst, rol: rolUser });
      }
    });
  }
  return institution;
};
export const validateInst = (institution, rolUser) => {
  let bolean = false;
  const result = institution.rols.filter((r) => {
    return r.name === rolUser;
  });
  if (result.length > 0) {
    bolean = true;
  }
  return bolean;
};
export const validateParam = (param, json) => {
  let aux = "";
  if (json) {
    if (json[param]) {
      aux = json[param];
    }
  }
  return aux;
};
export const validateEducator = (rols, cid) => {
  const result = rols.filter((r) => {
    if (cid) {
      return r.rolName === "educator" && r.courseId === parseInt(cid);
    } else {
      return r.rolName === "educator";
    }
  });
  return result.length > 0 ? true : false;
};
export const convertStringToJson = (str) => {
  do {
    if (str[0] === "'" && str[str.length - 1] === "'") {
      str = str.substring(1, str.length - 1);
    }
  } while (str[0] === "'" && str[str.length - 1] === "'");
  try {
    const objeto = JSON.parse(str.replace(/'/g, '"'));
    return objeto;
  } catch (error) {
    return null;
  }
};

export const convertString = (str) => {
  let objeto = "";
  do {
    if (str[0] === "'" && str[str.length - 1] === "'") {
      str = str.substring(1, str.length - 1);
    }
  } while (str[0] === "'" && str[str.length - 1] === "'");

  if (str) {
    try {
      objeto = JSON.parse(str.replace(/'/g, '"'));
    } catch (error) {
      objeto = str;
    }
  }
  return objeto;
};

export const convertString2 = (str) => {
  let objeto = "";
  do {
    if (str[0] === "'" && str[str.length - 1] === "'") {
      str = str.substring(1, str.length - 1);
    }
  } while (str[0] === "'" && str[str.length - 1] === "'");

  if (str) {
    objeto = JSON.parse(str.replace(/'/g, '"'));
  }
  return objeto;
};

export const capitalizeFirstLetter = (string) => {
  if (string === "" || string === null || string === undefined) {
    return "";
  }
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
};

export const getRandomColor = () => {
  const x = Math.floor(Math.random() * 256);
  const y = 100 + Math.floor(Math.random() * 256);
  const z = 50 + Math.floor(Math.random() * 256);
  return `rgb(${x},${y},${z},0.6)`;
};

export const validateBoolean = (state) => {
  const status = [true, false];
  if (status && status.indexOf(state) > -1) {
    return true;
  }
  return false;
};

export const diffElements = (arr1, arr2) => {
  return arr1
    .filter((x) => !arr2.includes(x))
    .concat(arr2.filter((x) => !arr1.includes(x)));
};
