import "./DefaultButton.css";

const DefaultButton = ({
  onClick,
  onCancel,
  textBtn,
  disabled,
  width,
  type = "button",
}) => {
  return (
    <button
      disabled={disabled}
      onClick={onClick}
      onCancel={onCancel}
      width={width}
      type={type}
    >
      {textBtn}
      <div className="arrow-wrapper">
        <div className="arrow"></div>
      </div>
    </button>
  );
};

export default DefaultButton;
