import React from "react";
import { Gift } from "react-feather";
import "./Thanks.css";

import { ReactComponent as GiftSVG } from "../../assets//images/undraw_gifts_0ceh.svg";
import { useEffect } from "react";
import { UseOnSubmit } from "../../hooks/useOnSubmit";
import { Link, useLocation, useParams } from "react-router-dom";
import { useAxios } from "../../hooks";
export default function Thanks() {
  const { id } = useParams();

  var currentLocation = window.location;

  const search = useLocation().search;

  const name = new URLSearchParams(currentLocation.search).get("Name");
  const email = new URLSearchParams(currentLocation.search).get("Email");
  const cel = new URLSearchParams(currentLocation.search).get("Cel");

  const onSubmit = async (values) => {
    let json = {
      Name: name,
      Email: email,
      Telephone: cel,
      PageId: id,
    };

    // console.log(nam)
    try {
      await UseOnSubmit({
        axiosParams: {
          method: "POST",
          url: "/contact",
          data: json,
        },
        process: true,
        txtSuccess: "Se le enviara su regalo al correo",
        txtWrong: "Hubo un error intentelo de nuevo",

        functionSuccess: (values) => {
          //   setTimeout(function () {
          //     navigate("/admin/pages/" + values.id);
          //   }, 1000);
        },
      });
    } catch (e) {
      console.log(e);
    }
  };

  const { response, loading } = useAxios({
    method: "GET",
    url: `/page/urlpage/${id}`,
  });

  useEffect(() => {
    if (email && name) {
      onSubmit();
    }
  }, []);
  return (
    <>
      <div className="background-thanks">
        <div>
          <div className="thanks_text_title">Felicidades</div>
          <div className="thanks_text">
            Puedes ir a tu correo para obtener el regalo
          </div>
          <div>
            <Gift color="var(--primary)" size={50} />
            <GiftSVG />
          </div>
          <div className="thanks_text">
            Tambien puedes revisar este curso de
            {loading ? null : <span> {response.nameProduct} </span>}
            aca
            {loading ? null : (
              <Link
                className="urlGift"
                to={response.urlProduct}
                target="_blank"
              >
                {" "}
                {response.urlProduct}
              </Link>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
