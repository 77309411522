import React from "react";
import "./Card.css";

const Card = ({ text, title, children, onClick }) => {
  return (
    <div onClick={onClick} className="card card--fixedWidth">
      <div className="card__description">
        <div className="icon card__descriptionIcon">{children}</div>
        <div className="card__descriptionText">{title}</div>
      </div>
      <div className="card__price">{text}</div>
    </div>
  );
};

export default Card;
