import React, { Component, useEffect, useState } from "react";
import "./Dashboard.css";
import logo from "../../../assets/images/funnelLogo.png"; // Tell webpack this JS file uses this image
import DefaultButton from "../../../components/buttons/DefaultButton";
import Table from "../../../components/table/Table";
import ModalForm from "../../../components/modal/Modal";
import { useAxiosAuth } from "../../../hooks";
import {
  Activity,
  CheckCircle,
  Copy,
  Crosshair,
  Delete,
  Edit,
  Edit2,
  Edit3,
  Gift,
  GitBranch,
  LogOut,
  MoreHorizontal,
  MoreVertical,
  Settings,
  Trash,
  User,
  Menu,
  X,
} from "react-feather";
import FunnelForm from "../../../components/form/FunnelForm";
import Spinner from "../../../components/spinner/Spinner";
import Card from "../../../components/cards/Card";
import config from "../../../config.json";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { UseOnSubmit } from "../../../hooks/useOnSubmit";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Slide, Zoom, Flip, Bounce } from "react-toastify";
import { Popover } from "react-tiny-popover";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css"; // optional
import EditFunnelForm from "../../../components/form/EditFunnelForm";
import { Steps, Hints } from "intro.js-react";
import "intro.js/introjs.css";

const Dashboard = () => {
  const username = window.localStorage.getItem("username");

  const [modal, setModal] = useState(false);
  const [stepEnabled, setStepEnabled] = useState(true);
  const [popover, setPopover] = useState(false);
  const [popOptions, setPopOptions] = useState(false);

  const [template, setTemplate] = useState(false);
  const [editFunnel, setEditFunnel] = useState(false);

  const [idTemplate, setidTemplate] = useState(null);
  const [selectedOption, setSelectedOption] = useState();

  const [idFunnel, setIdFunnel] = useState(null);

  const [url, setUrl] = useState("");
  const navigate = useNavigate();

  // Agregar estado para controlar la visibilidad del menú móvil
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  // Función para abrir/cerrar el menú móvil
  const toggleMobileMenu = () => {
    setMobileMenuOpen(!mobileMenuOpen);
  };

  let clickedRow = {};

  const { response, loading, reload } = useAxiosAuth({
    method: "GET",
    url: "/page",
  });

  const {
    response: responseTemplates,
    loading: loadingTemplates,
    reload: reloadTemplate,
  } = useAxiosAuth({
    method: "GET",
    url: `/Template${selectedOption ? "/" + selectedOption : ""}`,
  });

  const templateItems = loadingTemplates ? [] : responseTemplates;

  templateItems.map((item, index) => (
    <Card>
      <CheckCircle color="var(--yellow)" size={50} />
    </Card>
  ));

  function ComboBox() {
    const handleOptionChange = (event) => {
      setSelectedOption(event.target.value);
    };

    return (
      <div>
        <div className="combo">
          <select value={selectedOption} onChange={handleOptionChange}>
            <option value="">Todas las categorias</option>
            <option value="1">Comercio Internacional</option>
            <option value="2">Marketing y Ventas</option>
            <option value="3">Derecho / Legal</option>
            <option value="4">Finanzas / Contabilidad</option>
            <option value="5">Desarrollo Humano / Psicologia</option>
            <option value="6">Recursos Humanos</option>
            <option value="7">Tecnologia / Sistemas</option>
            <option value="8">Gestion Empresarial</option>
            <option value="9">Economia</option>
            <option value="10">
              Metodologia de Investigacion / Estadistica
            </option>
            <option value="11">Gastronimia</option>
            <option value="12">Salud y Belleza</option>
            <option value="13">Desarrollo Personal</option>
          </select>
          <button onClick={reloadTemplate}>Obtener Template</button>
        </div>
      </div>
    );
  }

  const nodes = loading ? [] : response;

  const data = { nodes };

  const COLUMNS = [
    { label: "ID", renderCell: (item) => item.id },
    {
      label: "Estado",
      renderCell: (item) =>
        item.published ? (
          <div className="statusTable">
            <div
              style={{
                backgroundColor: "rgb(62, 232, 175)",
                borderRadius: "1em",
                display: "flex",
                padding: ".1em 1em",
                color: "black",
              }}
            >
              Publicado
            </div>
          </div>
        ) : (
          <div className="statusTable">
            <div
              style={{
                backgroundColor: "var(--secondaryShadow)",
                borderRadius: "1em",
                display: "flex",
                padding: ".1em 1em",
                color: "whitesmoke",
              }}
            >
              Borrador
            </div>
          </div>
        ),
    },
    { label: "Nombre", renderCell: (item) => item.product.name },
    {
      label: "Url",
      renderCell: (item) =>
        item.published ? (
          <div className="flex_copy">
            <Link
              className="url"
              to={"/" + item.urlPage + "/" + item.id}
              target="_blank"
            >
              Abrir Funnel
            </Link>
            <Tippy content="Copiar Link">
              <div
                onClick={() => {
                  var aux = document.createElement("input");
                  aux.setAttribute(
                    "value",
                    window.location.origin + "/" + item.urlPage + "/" + item.id
                  );
                  document.body.appendChild(aux);
                  aux.select();
                  document.execCommand("copy");
                  document.body.removeChild(aux);

                  toast.info("Se copio el Funnel", {
                    position: "bottom-center",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: true,
                    progress: undefined,
                    transition: Zoom,
                  });
                }}
                className="icon_button"
              >
                <Copy />
              </div>
            </Tippy>
          </div>
        ) : (
          <div></div>
        ),
    },
    {
      label: "Editar",
      renderCell: (item) => (
        <div className="flex_row_actions">
          <Tippy content="Editar Pagina">
            <Link to={"/admin/pages/" + item.id} target="_blank">
              <div className="button_action">
                <Edit3 />
              </div>
            </Link>
          </Tippy>
          <Tippy content="Editar Datos del Funnel">
            <div
              onClick={(values) => {
                setEditFunnel(!editFunnel);
                setIdFunnel(item.productId);
              }}
              className="button_action"
            >
              <Gift />
            </div>
          </Tippy>

          <Tippy content="Estadisticas">
            <Link to={"/admin/statistic/" + item.id} target="_blank">
              <div className="button_action">
                <Activity />
              </div>
            </Link>
          </Tippy>

          <Tippy content="Borrar">
            <div onClick={() => onDelete(item.id)} className="button_action">
              <Trash />
            </div>
          </Tippy>
        </div>
      ),
    },
  ];

  const onDelete = async (id) => {
    try {
      await UseOnSubmit({
        axiosParams: {
          method: "POST",
          url: "/page/delete/" + id,
        },
        process: true,
        txtSuccess: "Borrado exitoso",
        txtWrong: "Erroral borrar",
        functionSuccess: reload,
      });
    } catch (e) {
      console.log(e);
    }
  };

  // const [selectedOption, setSelectedOption] = useState('opcion1'); // Estado para mantener la opción seleccionada
  const handleSelectTemplate = (item) => {
    // console.log("Select Template", item);
    setidTemplate(item.id);
    setTemplate(false);
    setModal(true);
  };

  const options = {
    showProgress: true,
    showBullets: true,
    exitOnOverlayClick: true,
    exitOnEsc: true,
    nextLabel: "Siguiente",
    prevLabel: "Anterior",
    // skipLabel: "Salir",
    hidePrev: true,
    doneLabel: "Done",
    overlayOpacity: 0.5,
    overlayColor: "#000",
    showStepNumbers: true,
    keyboardNavigation: true,
    scrollToElement: true,
    helperElementPadding: 10,
    showButtons: true,
    doneLabel: "Fin",
  };

  const steps = [
    {
      title: "Bienvenido al funnel de midas!!",
      intro:
        "Crea una página para promocionar tus ventas y compártela con tu audiencia..",
      // tooltipClass: "welcomeStep",
    },
    {
      element: "#createFunnel",
      title: "Con este botón podrás crear tu pagina",
      // intro: "You can jump to the User Settings page from here.",
      // position: "right",
    },
    {
      element: "#Avatar",
      title: "Aquí podrás cerrar sesión.",
      // position: "right",
    },
    {
      element: "#Generate",
      title: "Aqui podras generar Ideas para tus Regalos",
      intro:"Dale Click Para poder ver como generar Ideas",
      // position: "right",
    },
  ];

  const onExit = (count) => {
    if (count === 3) {
      window.localStorage.setItem("view_dashboard", true);
      // setStepEnabled(false);
    }
  };
  function onExitTemplate(count) {
    if (count === 1) {
      window.localStorage.setItem("view_template", true);
      // setStepEnabled(false);
    }
  }

  function onExitForm(count) {
    if (count === 2) {
      window.localStorage.setItem("view_form", true);
      // setStepEnabled(false);
    }
  }
  return (
    <>
      {window.localStorage.getItem("view_dashboard") === "true" ? null : (
        <Steps
          enabled={stepEnabled}
          steps={steps}
          initialStep={0}
          onExit={onExit}
          options={options}
        />
      )}
      <section className="layoutDashboard">
        <div className="header">
          <div className="logo">
            <img src={logo} alt="logo" />
          </div>
          <div className="nav">
            {/* Agregar el botón de menú hamburguesa */}
            <div className="mobile-menu-button" onClick={toggleMobileMenu}>
              <Menu />
            </div>
            <ul className={`list ${mobileMenuOpen ? "open" : ""}`}>
              <li>
                <Link to="/Home" className="nav-link">
                  INICIO
                </Link>
              </li>
              <li>
                <Link to="/admin/dashboard" className="nav-link">
                  FUNNELS
                </Link>
              </li>
              <li id = "Generate">
                <Link to="/GenerateResource" className="nav-link">
                  GENERADOR DE IDEAS
                </Link>
              </li>
              <li id="oculto">
                <Link
                  onClick={() => {
                    window.localStorage.setItem("token_seguridad", "");
                  }}
                  to="/admin/login"
                  className="nav-link"
                >
                  CERAR SESION
                </Link>
              </li>
            </ul>
            <Popover
              isOpen={popover}
              positions={["bottom", "top", "left", "right"]} // preferred positions by priority
              content={
                <div
                  className="popover"
                  onClick={() => {
                    navigate("/admin/login");
                    window.localStorage.setItem("token_seguridad", "");
                  }}
                >
                  Cerrar Sesión <LogOut />
                </div>
              }
            >
              <div
                id = "Avatar"
                className="avatar"
                onClick={() => {
                  setPopover(!popover);
                }}
              >
                <User />
                {username}
              </div>
            </Popover>
          </div>
        </div>
        <div className="main">
          <div className="top selector1">
            Funnels
            <div id="createFunnel" className="">
              <DefaultButton
                onClick={() => setTemplate(true)}
                textBtn={"Crear Funnel"}
              />
            </div>
          </div>

          <div className="table">
            {loading ? (
              <div className="spinner-center">
                <Spinner />
              </div>
            ) : (
              <Table columns={COLUMNS} data={data} />
            )}
          </div>
          <div className="modal">
            <ModalForm isOpen={modal}>
              {window.localStorage.getItem("view_form") === "true" ? null : (
                <Steps
                  enabled={stepEnabled}
                  steps={[
                    {
                      element: "#NameProduct",
                      title: "Adiciona el nombre del producto que deseas regalar",
                      intro: "",
                      // tooltipPosition: "bottom-center",
                    },
                    {
                      element: "#UrlProduct",
                      title: "Aca podrás agregar la URL del producto que se venderá",
                      //   "Para seguir con el siguiente paso debes elegir un template.",
                      // tooltipPosition: "bottom-center",
                    },
                    {
                      element: "#FileUpload",
                      title: "En esta sección podrá subir un regalo que desea brindar a los usuarios, en formato PDF",
                      // intro:
                      //   "Para seguir con el siguiente paso debes elegir un template.",
                      // tooltipPosition: "bottom-center",
                    },
                    // {
                    //   element: "#modal-cards",
                    //   title: "Luego elije uno de los templates",
                    //   // position: "top",
                    //   // intro: "You can jump to the User Settings page from here.",
                    //   tooltipPosition: "bottom-center",
                    // },
                  ]}
                  initialStep={0}
                  onExit={onExitForm}
                  options={options}
                />
              )}
              <div className="modal-header">
                <X
                  className="modal-button-close scale-up-horizontal-center"
                  onClick={() => setModal(false)}
                />

                <h2>Crear Funnel</h2>
              </div>

              <FunnelForm idTemplate={idTemplate} />
            </ModalForm>
            {/* Select template */}

            <ModalForm isOpen={template}>
              {window.localStorage.getItem("view_template") ===
              "true" ? null : (
                <Steps
                  enabled={stepEnabled}
                  steps={[
                    {
                      element: "#modal-title",
                      title: "Este es el formulario para crear un template",
                      tooltipPosition: "bottom-center",
                    },
                    {
                      element: "#modal-combobox",
                      title: "Aquí podrás filtrar los templates por categorías",
                      intro:
                        "Para seguir con el siguiente paso debes elegir un template.",
                      tooltipPosition: "bottom-center",
                    },
                    // {
                    //   element: "#modal-cards",
                    //   title: "Luego elije uno de los templates",
                    //   // position: "top",
                    //   // intro: "You can jump to the User Settings page from here.",
                    //   tooltipPosition: "bottom-center",
                    // },
                  ]}
                  initialStep={0}
                  onExit={onExitTemplate}
                  options={options}
                />
              )}
              <div className="modal-header">
                <X
                  className="modal-button-close scale-up-horizontal-center"
                  onClick={() => setTemplate(false)}
                />

                <h2 id="modal-title">Seleccione un Template</h2>
              </div>
              <div id="modal-combobox">
                <ComboBox />
              </div>
              <div className="modal-cards">
                {templateItems.map((item, index) => (
                  <div
                    key={index}
                    className="modal-cards-item"
                    id={index === 0 ? "card-item" : ""}
                  >
                    <img
                      className="imagen_templates scale-up-card-center"
                      onClick={() => handleSelectTemplate(item)}
                      src={item.photoUrl}
                      alt=""
                    ></img>
                  </div>
                ))}
              </div>
            </ModalForm>
            <ModalForm isOpen={editFunnel}>
              <div className="modal-header">
                <X
                  className="modal-button-close scale-up-horizontal-center"
                  onClick={() => setEditFunnel(false)}
                />

                <h2>Ingrese los datos a Editar</h2>
              </div>
              <div className="modal-cards">
                <EditFunnelForm
                  reload={reload}
                  theModal={setEditFunnel}
                  idFunnel={idFunnel}
                />
              </div>
            </ModalForm>
          </div>
        </div>
        <ToastContainer />
      </section>
    </>
  );
};

export default Dashboard;
