import axios from 'axios'
import { validateStatus } from '../utils/Validation'
import { isValid, convertDate } from '../utils/ConvertDate'
import HttpAuth from '../utils/HttpAuth'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { Slide, Zoom, Flip, Bounce } from 'react-toastify'

const instancia = axios.create()
instancia.CancelToken = axios.CancelToken
instancia.isCancel = axios.isCancel

export const UseOnSubmit = async (props) => {
  const {
    axiosParams,
    functionSuccess,
    functionWrong,
    txtSuccess = 'Ingreso Exitoso',
    txtWrong = 'Ingreso Fallido',
    process = false,
    auth = true,
  } = props

  let status = null
  let error = ''
  let loading = true
  let last = false

  const actionAxios = auth ? HttpAuth : instancia

  try {
    const result = await actionAxios.request(
      process ? ProcessBody(axiosParams) : axiosParams,
    )
    status = result.status || 400
    if (validateStatus(status)) {
      validateFunction(functionSuccess, result.data)

      toast.success(txtSuccess, {
        position: 'bottom-center',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        transition: Zoom,
      })
    } else {
      validateFunction(functionWrong, result.data)
      toast.error(txtWrong, {
        position: 'bottom-center',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        transition: Zoom,
      })
    }
  } catch (e) {
    validateFunction(functionWrong)
    toast.error(txtWrong, {
      position: 'bottom-center',
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
      transition: Zoom,
    })
    error = e
  } finally {
    loading = false
  }

  return { status, error, loading }
}

function validateFunction(action, data = null) {
  if (action) {
    action(data)
  }
}

function ProcessBody(params) {
  const { method, url, data } = params
  for (let key in data) {
    if (isValid(data[key])) {
      data[key] = convertDate(data[key])
    }
  }
  return { method, url, data }
}
