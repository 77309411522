import React, { useCallback, useState } from "react";
import { Link, useNavigate, withRouter } from "react-router-dom";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import "./Form.css";
import { UseOnSubmit } from "../../hooks/useOnSubmit";
import FormikController from "../../utils/FormikController";
import { FileUploader } from "react-drag-drop-files";
import DefaultButton from "../buttons/DefaultButton";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Slide, Zoom, Flip, Bounce } from "react-toastify";
import Spinner from "../spinner/Spinner";

function UserForm({
  idTemplate,
  url,
  txtSuccess,
  txtWrong,
  valuesUSer,
  textBtn = "Crear Usuario",
  theModal,
  reload
}) {
  const navigate = useNavigate();
  const [disabled, setDisabled] = useState(false);
  const [loader, setLoader] = useState(false);

  const onSubmit = async (values) => {
    try {
      setLoader(true);
      values.Rol = [{ id: 2, Name: "admin" }];
      values.Ci = values.Ci.toString();
      values.PhoneNumber = values.PhoneNumber.toString();

      await UseOnSubmit({
        axiosParams: {
          method: valuesUSer
          ?"PUT" :"POST",
          url: url,
          data: values,
        },
        process: true,
        txtSuccess: txtSuccess,
        txtWrong: txtWrong,
        functionWrong: () => {
          setLoader(false);

          setDisabled(false);
        },
        functionSuccess: () => {
          reload()
          setLoader(false);
          theModal(false);
          setTimeout(function () {
            setDisabled(false);
          }, 1000);
        },
      });
    } catch (e) {
      console.log(e);
    }
  };

  const initialValues = valuesUSer
    ? {
        Username: valuesUSer.username ?? "",
        Email: valuesUSer.email ?? "",
        Name: valuesUSer.name ?? "",
        FatherLastName: valuesUSer.fatherLastName ?? "",
        MotherLastName: valuesUSer.motherLastName ?? "",
        Ci: valuesUSer.ci ?? "",
        PhoneNumber: valuesUSer.phoneNumber ?? "",
        PhotoUrl: null,
      }
    : {
        Username: "",
        Password: "1234",
        Email: "",
        Name: "",
        FatherLastName: "",
        MotherLastName: "",
        Ci: "",
        PhoneNumber: "",
        PhotoUrl: null,
      };

  // console.log(initialValues);

  const validationSchema = Yup.object({
    Username: Yup.string().required("Requerido"),
    Email: Yup.string().email("Ingresar un email valido").required("Requerido"),
    Name: Yup.string().required("Requerido"),
    FatherLastName: Yup.string().required("Requerido"),
    MotherLastName: Yup.string().required("Requerido"),
    Ci: Yup.number()
      .positive("Solo valores positivos")
      .required("Solo ingresar numeros"),
    PhoneNumber: Yup.number("Solo ingresar numeros")
      .positive("Solo valores positivos")
      .required("Solo ingresar numeros"),
  });

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {(formik) => (
        <Form>
          <div className="flex_column_form">
            <div className="flex_row_form">
              <FormikController
                control="input"
                type="text"
                label="Nombre de Usuario"
                name="Username"
                disabled={valuesUSer ?? false}
              />
              <FormikController
                control="input"
                type="email"
                label="Email"
                name="Email"
              />
              <FormikController
                control="input"
                type="number"
                label="Número"
                name="PhoneNumber"
              />
            </div>
            <div className="flex_row_form">
              <FormikController
                control="input"
                type="text"
                label="Nombre"
                name="Name"
              />
              <FormikController
                control="input"
                type="text"
                label="Apellido Paterno"
                name="FatherLastName"
              />
              <FormikController
                control="input"
                type="text"
                label="Apellido Materno"
                name="MotherLastName"
              />
            </div>
            <div className="flex_row_form">
              <FormikController
                control="input"
                type="number"
                label="Cédula de Identidad"
                name="Ci"
              />
            </div>

            <DefaultButton
              id="button-submit"
              type="submit"
              textBtn={textBtn}
              disabled={disabled}
            />
          </div>
          {loader ? <Spinner /> : <></>}
        </Form>
      )}
    </Formik>
  );
}

export default UserForm;
