import { Navigate } from "react-router-dom";

const PrivateRoute = ({ children }) => {
  const token = window.localStorage.getItem("token_seguridad");

  const authed = token ? true : false;

  return authed ? children : <Navigate to="/" />;
};

export default PrivateRoute;
