import Modal from "react-modal";
import React from "react";
import "./Modal.css";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "var(--background)",
    color: "var(--primary)",
    border: "1px solid black",
    maxHeight: "95vh",
  },
  overlay: {
    backgroundColor: "var(--primaryshadowtransparent)",
  },
};

const ModalForm = ({ text, title, isOpen = false, children }) => {
  return (
    <div id="modal">
      <Modal
        isOpen={isOpen}
        style={customStyles}
        contentLabel="Funnel Modal"
        shouldCloseOnEsc={true}
        shouldCloseOnOverlayClick={true}
        appElement={document.getElementById("root") || undefined}
      >
        {children}
      </Modal>
    </div>
  );
};

export default ModalForm;
