import React from "react";
import Checkboxes from "../components/inputs/CheckBox";
import Input from "../components/inputs/Input";
import RadioButtons from "../components/inputs/Radio";
import Select from "../components/inputs/Select";

function FormikController(props) {
  const { control, ...rest } = props;
  switch (control) {
    case "input":
      return <Input {...rest} />;
    case "textArea":
      return <textarea {...rest} />;
    case "select":
      return <Select {...rest} />;
    case "radio":
      return <RadioButtons {...rest} />;
    case "checkbox":
      return <Checkboxes {...rest} />;
    default:
      return null;
  }
}
export default FormikController;
