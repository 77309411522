import React from "react";
import { Field, ErrorMessage } from "formik";
import "./LoginInput.css";

function Input(props) {
  const { name, label, type, ...rest } = props;
  return (
    <div id={name} className="flex_column">
      <p className="label-input">{label}</p>
      <i className="fas fa-user"></i>
      <Field
        className="input"
        placeholder={label}
        type={type}
        name={name}
        {...rest}
      />
      <ErrorMessage name={name} />
    </div>
  );
}
export default Input;
