import React, { Component, useState } from "react";
import "../Dashboard.css";
import logo from "../../../../assets/images/logo.png"; // Tell webpack this JS file uses this image
import DefaultButton from "../../../../components/buttons/DefaultButton";
import Table from "../../../../components/table/Table";
import { useAxiosAuth } from "../../../../hooks";
import {
  CheckCircle,
  Copy,
  Crosshair,
  Delete,
  Edit,
  Edit2,
  Edit3,
  Gift,
  LogOut,
  MoreHorizontal,
  MoreVertical,
  Settings,
  Trash,
  User,
  X,
} from "react-feather";
import Spinner from "../../../../components/spinner/Spinner";
import Card from "../../../../components/cards/Card";
import { Link, NavLink, useNavigate, useParams } from "react-router-dom";
import { UseOnSubmit } from "../../../../hooks/useOnSubmit";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Slide, Zoom, Flip, Bounce } from "react-toastify";
import { Popover } from "react-tiny-popover";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css"; // optional

const Statistic = () => {
  const username = window.localStorage.getItem("username");

  const [popover, setPopover] = useState(false);
  const { id } = useParams();

  const navigate = useNavigate();

  let clickedRow = {};

  const { response, loading, reload } = useAxiosAuth({
    method: "GET",
    url: `/contact/page/${id}`,
  });

  const { response: responseProduct, loading: loadingProduct } = useAxiosAuth({
    method: "GET",
    url: `/page/urlpage/${id}`,
  });

  const nodes = loading
    ? []
    : response.filter(
        (v, i, a) => a.findIndex((v2) => v2.email === v.email) === i
      );

  const data = { nodes };

  const COLUMNS = [
    { label: "Nombre del Cliente", renderCell: (item) => item.name },

    { label: "Correo", renderCell: (item) => item.email },
    {
      label: "Celular",
      renderCell: (item) => item.telephone,
    },
    {
      label: "Fecha de Registro",
      renderCell: (item) => convertDate(item.creationDate),
    },
  ];

  function convertDate(inputFormat) {
    function pad(s) {
      return s < 10 ? "0" + s : s;
    }
    var d = new Date(inputFormat);
    return [pad(d.getDate()), pad(d.getMonth() + 1), d.getFullYear()].join("/");
  }

  return (
    <section className="layoutDashboard">
      <div className="header">
        <div className="logo">
          <img src={logo} alt="Logo" />
          <span>FUNNEL</span>
        </div>

        <Popover
          isOpen={popover}
          positions={["bottom", "top", "left", "right"]} // preferred positions by priority
          content={
            <div
              className="popover"
              onClick={() => {
                navigate("/admin/login");
                window.localStorage.setItem("token_seguridad", "");
              }}
            >
              Cerrar Sesión <LogOut />
            </div>
          }
        >
          <div
            className="avatar"
            onClick={() => {
              setPopover(!popover);
            }}
          >
            <User />
            {username}
          </div>
        </Popover>
      </div>
      <div className="main">
        <div className="top">
          Contactos
          <div>
            {loadingProduct ? null : <p>{responseProduct.nameProduct}</p>}

            {loadingProduct ? null : (
              <a
                className="url"
                href={"https://"+responseProduct.urlProduct}
                target="_blank"
                rel="noopener noreferrer"
              >
                {responseProduct.urlProduct}
              </a>
            )}
          </div>
        </div>

        <div className="table">
          {loading ? (
            <div className="spinner-center">
              <Spinner />
            </div>
          ) : (
            <Table columns={COLUMNS} data={data} />
          )}
        </div>
      </div>
      <ToastContainer />
    </section>
  );
};

export default Statistic;
