import moment from "moment";
import "moment/locale/es";
moment.locale("es");
export function convertD(
  dateConv,
  formatDate,
  formatConv = "YYYY-MM-DDTHH:mm:ss"
) {
  if (dateConv) {
    if (formatDate === "LLLL") {
      const date = moment(dateConv, "YYYY-MM-DDTHH:mm:ss").format("LL");
      const hour = moment(dateConv, "YYYY-MM-DDTHH:mm:ss").format("LT");
      return date + " a horas " + hour;
    } else if (formatDate === "utc") {
      if (dateConv === "0001-01-01T00:00:00") {
        return null;
      } else {
        return new Date(dateConv);
      }
    } else {
      return moment(dateConv, formatConv).format(formatDate);
    }
  }
  return null;
}
export function addToDate(date, TypeAdd, number) {
  return moment(date).add(number, TypeAdd).format("YYYY-MM-DDTHH:mm:ss");
}
export function convertDate(dateConv, formatDate = "YYYY-MM-DD HH:mm:ss") {
  return moment(dateConv).format(formatDate);
}
export function convertDateLiteral(dateConv) {
  const momentDate = moment(dateConv).format("MM");
  return months[momentDate - 1] + " de " + moment(dateConv).format("YYYY");
}
export function dataLock(date) {
  const day = moment().clone().format("YYYY-MM-DDTHH:mm:ss");
  return day > date;
}
export function getDay(date) {
  return moment(date).format("D");
}
export function getMount(date) {
  return moment(date).format("MMM");
}
export function getAgo(date) {
  return moment(date).fromNow();
}
export function getAge(date) {
  const nacimiento = moment(date);
  const hoy = moment();
  return hoy.diff(nacimiento, "years");
}
export function validateEventArray(date, dateArray) {
  let evt = [];
  const d = date.format("YYYY-MM-DD").toString();
  if (dateArray !== null && dateArray !== undefined) {
    dateArray.forEach((element) => {
      let e = moment(element.date).format("YYYY-MM-DD").toString();
      if (e === d) {
        evt.push(element);
      }
    });
  }
  return evt;
}
export function validateEventBool(date, dateArray) {
  let boolSW = false;
  if (dateArray !== null && dateArray !== undefined) {
    dateArray.forEach((element) => {
      if (
        moment(element.date).format("YYYY-MM-DD").toString() ===
        date.format("YYYY-MM-DD").toString()
      ) {
        boolSW = true;
      }
    });
  }
  return boolSW;
}
export function dateLockRange(startDate, finishDate) {
  let boolSW = true;
  const day = moment().clone().format("YYYY-MM-DDTHH:mm:ss");
  if (startDate < day) {
    boolSW = false;
  } else {
  }
  if (finishDate > day) {
    console.log("finishDate no cumple", finishDate > day);
    boolSW = false;
  }
  return boolSW;
}
export function orderDates(dates) {
  if (dates) {
    const dd = dates.sort(function (left, right) {
      return moment
        .utc(left.type === "virtualclass" ? left.startDate : left.finishDate)
        .diff(
          moment.utc(
            right.type === "virtualclass" ? right.startDate : right.finishDate
          )
        );
    });
    const lessons = [];
    const modules = [];
    dd.forEach((e) => {
      if (e.type === "module") {
        modules.push(e);
      } else {
        lessons.push(e);
      }
    });
    return [...lessons, ...modules];
  }
  return dates;
}

export function convertHour(time) {
  return moment(time, "HH:mm:ss").subtract(1, "second").format("HH:mm:ss");
}
export function subtractDate(time, subt, type) {
  var date = moment(time).subtract(subt, type).format("YYYY-MM-DDTHH:mm");
  return date;
}

export function convertSeconds(seg, format) {
  return moment.utc(seg * 1000).format(format);
}

export function calculateAge(dateConv) {
  if (moment(dateConv).isValid()) {
    const age = moment().diff(dateConv, "years");
    if (age < 150) {
      return age;
    } else {
      return "";
    }
  } else {
    return "";
  }
}
export function isToday(day) {
  return (
    moment(day).isSame(new Date(), "day") ||
    moment(day).subtract(1, "day").isSame(new Date(), "day")
  );
}

export function isValid(day) {
  return moment(day, "utc", true).isValid();
}
export function isValidFormat(day, format = "YYYY-MM-DDTHH:mm:ss") {
  return (
    moment(day, format, true).isValid() ||
    moment(day, "YYYY-MM-DDTHH:mm:ss.SSS", true).isValid()
  );
}
export function diffDate(date) {
  const fecha1 = moment();
  const fecha2 = moment(date);
  let secondsDiff = fecha2.diff(fecha1, "seconds");
  const days = Math.floor(secondsDiff / 86400);
  secondsDiff -= days * 86400;
  const hours = Math.floor(secondsDiff / 3600) % 24;
  secondsDiff -= hours * 3600;
  const minutes = Math.floor(secondsDiff / 60) % 60;
  secondsDiff -= minutes * 60;
  const seconds = secondsDiff % 60;
  return { days, hours, minutes, seconds };
}

const months = [
  "Enero",
  "Febrero",
  "Marzo",
  "Abril",
  "Mayo",
  "Junio",
  "Julio",
  "Agosto",
  "Septiembre",
  "Octubre",
  "Noviembre",
  "Diciembre",
];
