import * as React from "react";

import { CompactTable } from "@table-library/react-table-library/compact";
import { useTheme } from "@table-library/react-table-library/theme";
import "./Table.css";

const Table = ({ columns, data }) => {
  const theme = useTheme({
    Table: `
        border: 1px solid var(--primaryshadow);
        border-radius: 1em;
      `,
    HeaderRow: `
        .th {
          border-bottom: 1px solid var(--primaryshadow);
        }
          background-color: var(--background);
          color: var(--primary);
      `,
    Row: `
        &:not(:last-of-type) .td {
          border-bottom: 1px solid var(--primaryshadow);
          
        }
          background-color: var(--background);
                    color: var(--primary);
      `,
    BaseCell: `
        &:not(:last-of-type) {
          border-right: 1px solid var(--primaryshadow);
        }
        text-align: center;
        color: var(--primary)
        background-color: var(--background);

      `,
  });

  return (
    <CompactTable
      columns={columns}
      data={data}
      theme={theme}
      layout={{ fixedHeader: false }}
    />
  );
};

export default Table;
