import { useEffect, useState } from "react";
import grapesjs from "grapesjs";
import "grapesjs/dist/css/grapes.min.css";
import "grapesjs/dist/grapes.min.js";
import { useParams } from "react-router-dom";
import { useAxiosAuth } from "../../../hooks";
import { UseOnSubmit } from "../../../hooks/useOnSubmit";
import "./Editor.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom/dist";
import "grapesjs-plugin-toolbox/dist/grapesjs-plugin-toolbox.min.css";
import rtetext from "grapesjs-rte-extensions";
import webpage from "grapesjs-preset-webpage";
import basic from "grapesjs-blocks-basic";
import gjsForms from "grapesjs-plugin-forms";
import "grapesjs-rte-extensions/dist/grapesjs-rte-extensions.min.css";
import grapesJSMJML from "grapesjs-mjml";
import parserPostCSS from 'grapesjs-parser-postcss';
import gradient from 'grapesjs-style-bg';
import 'grapick/dist/grapick.min.css';
import Modal from 'react-modal';
import ReactPlayer from 'react-player';

function Editor() {
  const { id } = useParams();
  const [editor, seteditor] = useState(null);
  const navigate = useNavigate();
  const [modalIsOpen, setModalIsOpen] = useState(true);

  const { response, loading } = useAxiosAuth({
    method: "GET",
    url: "/page/" + id,
  });
  loading ? console.log("loading") : loadPage();

  const onSubmit = async (values) => {
    // console.log(values);

    try {
      await UseOnSubmit({
        axiosParams: {
          method: "PUT",
          url: "/page/Update/" + id,
          data: {
            Html: values.html,
            Css: values.css,
            UrlPage: values.url,
            Published: values.published,
          },
        },
        process: true,
        txtSuccess: values.txtSuccess,
        txtWrong: values.txtFailure,
        functionSuccess: (values) => {
          if (values.published) {
            setTimeout(function () {
              navigate("/admin/dashboard/");
            }, 1000);
          }
        },
      });
    } catch (e) {
      console.log(e);
    }
  };

  // console.log(response);
  useEffect(() => {
    const editor = grapesjs.init({
      container: "#gjs",
      height: "100vh",
      width: "100%",
      plugins: [rtetext, gjsForms, basic, parserPostCSS, gradient],
      // storageManager: false,
      storageManager: {
        id: "gjs-",
        type: "local",
        autosave: true,
        storeComponents: true,
        storeStyles: true,
        storeHtml: true,
        storeCss: true,
      },

      pluginsOpts: {
        [basic]: {
          // blocks: ["column1","column2", "column3-7", "text", "image", "video"],
        },
        [grapesJSMJML]: {
          blocks: [],
        },
        [gjsForms]: {
          blocks: [],
        },
      },

      // dragMode: "absolute", // 'absolute' | 'translate'
    });

    editor.BlockManager.add("Cita", {
      label: "Quote",
      media: `<svg viewBox="0 0 24 24">
        <path fill="currentColor" d="M14,17H17L19,13V7H13V13H16M6,17H9L11,13V7H5V13H8L6,17Z" />
    </svg>`,
      category: "Basic",
      content: `<blockquote class="quote">
    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore ipsum dolor sit
  </blockquote>`,
    });

    editor.BlockManager.add("Texto", {
      label: "Text section",
      media: `<svg viewBox="0 0 24 24">
        <path fill="currentColor" d="M21,6V8H3V6H21M3,18H12V16H3V18M3,13H21V11H3V13Z" />
    </svg>`,
      category: "Basic",
      content: `<section class="bdg-sect">
      <h1 class="heading">Insert title here</h1>
      <p class="paragraph">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua</p>
      </section>`,
    });

    editor.BlockManager.add("link-block", {
      label: "Link Block",
      category: "Basic",
      media: `<svg viewBox="0 0 24 24">
        <path fill="currentColor" d="M3.9,12C3.9,10.29 5.29,8.9 7,8.9H11V7H7A5,5 0 0,0 2,12A5,5 0 0,0 7,17H11V15.1H7C5.29,15.1 3.9,13.71 3.9,12M8,13H16V11H8V13M17,7H13V8.9H17C18.71,8.9 20.1,10.29 20.1,12C20.1,13.71 18.71,15.1 17,15.1H13V17H17A5,5 0 0,0 22,12A5,5 0 0,0 17,7Z"></path>
      </svg>`,
      content: {
        content: '<p class="link">Soy un link</p>',
        type: "link",
        editable: true,
        droppable: true,
        style: {
          display: "inline-block",
          padding: "5px",
          width: "100%",
        },
      },
    });


   

    editor.BlockManager.add("Contact", {
      label: "Contact",
      media: `<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 1000 1000" enable-background="new 0 0 1000 1000" xml:space="preserve">
      <metadata> Svg Vector Icons : http://www.onlinewebfonts.com/icon </metadata>
      <g><path d="M737.3,302.9c0-16.8-13.6-30.4-30.4-30.4H175.1c-16.8,0-30.4,13.6-30.4,30.4c0,16.8,13.6,30.4,30.4,30.4h531.8C723.7,333.3,737.3,319.7,737.3,302.9z"/><path d="M594.9,484.6c0-16.8-13.6-30.4-30.4-30.4H175.1c-16.8,0-30.4,13.6-30.4,30.4c0,16.8,13.6,30.4,30.4,30.4h389.5C581.3,514.9,594.9,501.3,594.9,484.6z"/><path d="M175.1,636.4c-16.8,0-30.4,13.6-30.4,30.4c0,16.8,13.6,30.4,30.4,30.4H363c16.8,0,30.4-13.6,30.4-30.4c0-16.8-13.6-30.4-30.4-30.4H175.1z"/><path d="M799.4,788.4c0,33.5-27.2,60.7-60.7,60.7H131.4c-33.5,0-60.7-27.2-60.7-60.7V211.6c0-33.5,27.2-60.7,60.7-60.7h30.4c0.8,0,1.7,0,2.5-0.1h541.5c0.8,0.1,1.7,0.1,2.5,0.1h30.4c33.5,0,60.7,27.2,60.7,60.7v24.7l60.7-7.3v-17.4c0-67.1-54.4-121.4-121.4-121.4h-1.6h-28.8H161.8h-19.3h-11.1C64.4,90.1,10,144.5,10,211.6v576.9c0,67.1,54.4,121.4,121.4,121.4h607.2c67.1,0,121.4-54.4,121.4-121.4V611.2l-60.7,7.3L799.4,788.4L799.4,788.4z"/><path d="M982.1,353.3l-72.7-72.7c-10.5-10.5-27.5-10.5-38,0l-43,43c-0.2,0.2-0.4,0.4-0.6,0.6l110.7,110.7c0.2-0.2,0.4-0.4,0.6-0.6l43-43C992.6,380.8,992.6,363.8,982.1,353.3z"/><path d="M539.3,611.6L539,722.1l113.2,0.2l262.2-262.2L802.7,348.3L539.3,611.6z M658.2,665l-32.7,2.5l2.8-35.2l-34.7,1.1l4.5-30.2l39.1-39.1l60.9,60.9L658.2,665z M810.9,401.3c3.6,3.6,3.6,9.3,0,12.9L671.2,553.9c-3.6,3.6-9.3,3.6-12.9,0c-3.6-3.6-3.6-9.3,0-12.9l139.8-139.8C801.6,397.7,807.4,397.7,810.9,401.3z"/></g>
      </svg>`,

      content: `
      <form method="get" action="/thanks/$$$$$$$$PAGE$$$$$$$$$">
      <div class="flex_column">
        <label class="label-input">Nombre</label>
        <input type="text" required name="Name" class="input"/>
      </div>
      <div class="flex_column">
        <label class="label-input">Email</label>
        <input type="email" placeholder="" required name="Email" class="input"/>
      </div>
      <div class="flex_column">
        <label class="label-input">Celular</label>
        <input type="number" required name="Cel" class="input"/>
      </div>
      <button type="submit">Enviar</button>
    </form>

    <style> 
    form{
      background-color: rgba(245, 245, 245, 0.421);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: .2em;
      width: 50%;
    } 

    .input {
      line-height: 2em;
      border: 2px solid transparent;
      border-bottom-color: #8282a4;
      padding: 0.2rem 2em;
      border-radius: 0.1rem;
      outline: none;
      background-color: white;
      color: #8282a4;
      transition: 0.5s cubic-bezier(0.645, 0.045, 0.355, 1);
      -webkit-border-radius: 0.1rem;
      -moz-border-radius: 0.1rem;
      -ms-border-radius: 0.1rem;
      -o-border-radius: 0.1rem;
    }
    
    .input:focus,
    input:hover {
      outline: none;
      padding: 0.2rem 2em;
      border-radius: 1rem;
      border-color: #8282a4;
    }
    
    .input::placeholder {
      color: #8282a4;
    }
    
    input:disabled,
    input[disabled] {
      background-color: #cccccc;
      color: #3a3ba0;
      font-weight: bolder;
    }
    
    .input:focus::placeholder {
      opacity: 0;
      transition: opacity 0.8s;
    }
    
    .error * {
      color: #fa4658;
    }
    
    .flex_column {
      color: #fa4658;
    
      display: flex;
      flex-direction: column;
      gap: 0.2em;
      text-align: center;
      justify-content: center;
    }
    
    .label-input {
      font-size: clamp(1rem, 8vw - 8rem, 3rem);
      font-weight: bold;
      color: black;
    }

    button {
      box-sizing: border-box;
      border: 0;
      border-radius: 20px;
      color: white;
      padding: 1em 1.8em;
      background: #fa4658;
      display: flex;
      transition: 0.2s background;
      align-items: center;
      gap: 0.6em;
      font-weight: bold;
      text-align: center;
      align-items: center;
      justify-content: center;
    }
    
    button .arrow-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    
    button .arrow {
      margin-top: 1px;
      width: 10px;
      background: #fa4658;
      height: 2px;
      position: relative;
      transition: 0.2s;
    }
    
    button .arrow::before {
      content: "";
      box-sizing: border-box;
      position: absolute;
      border: solid white;
      border-width: 0 2px 2px 0;
      display: inline-block;
      top: -3px;
      right: 3px;
      transition: 0.2s;
      padding: 3px;
      transform: rotate(-45deg);
    }
    
    button:hover {
      background-color: #3a3ba0;
    }
    
    button:hover .arrow {
      background: white;
    }
    
    button:hover .arrow:before {
      right: 0;
    }

    input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
}

input[type=number] { -moz-appearance:textfield; }
    
    </style>
  `,
    });

      // Add Import button


  // Add Undo/Redo buttons
  editor.Panels.addButton('options', {
    id: 'undo',
    command: 'core:undo',
    attributes: 'undo',
    label: `<svg style="display: block; max-width:22px" viewBox="0 0 24 24">
        <path fill="currentColor" d="M20 13.5C20 17.09 17.09 20 13.5 20H6V18H13.5C16 18 18 16 18 13.5S16 9 13.5 9H7.83L10.91 12.09L9.5 13.5L4 8L9.5 2.5L10.92 3.91L7.83 7H13.5C17.09 7 20 9.91 20 13.5Z" />
    </svg>`
  });
  editor.Panels.addButton('options', {
    id: 'redo',
    command: 'core:redo',
    attributes: `redo`,
    label: `<svg style="display: block; max-width:22px" viewBox="0 0 24 24">
        <path fill="currentColor" d="M10.5 18H18V20H10.5C6.91 20 4 17.09 4 13.5S6.91 7 10.5 7H16.17L13.08 3.91L14.5 2.5L20 8L14.5 13.5L13.09 12.09L16.17 9H10.5C8 9 6 11 6 13.5S8 18 10.5 18Z" />
    </svg>`,
  });

  editor.Panels.addButton("options", [
    {
      id: "publish",
      label: " Publicar",
      className: "fa fa-paper-plane",
      command: function (editor, sender) {
        const url = "funnel";
        var html = editor.getHtml();
        var css = editor.getCss();
        onSubmit({
          html: html,
          css: css,
          published: true,
          url: url,
          txtSuccess: "Publicacion exitosa",
          txtFailure: "Error al publicar",
        });
      },
      attributes: { title: "Publish Template" },
    },
    {
      id: "Guardar",
      label: " Guardar",
      className: "fa fa-folder",
      command: function (editor, sender) {
        const url = "funnel";

        var html = editor.getHtml();
        console.log(
          "🚀 ~ file: Editor.jsx ~ line 189 ~ useEffect ~ html",
          html
        );
        var css = editor.getCss();

        onSubmit({
          html: html,
          css: css,
          url: url,
          txtSuccess: "Guardado exitoso",
          txtFailure: "Error al guardar",
        });
      },
      attributes: { title: "Save Template" },
    },
  ]);
  const sectors = editor.StyleManager.getSectors();

 

    // editor.addComponents(``);
    // editor.addComponents(`
    // <mjml>
    //   <mj-body width="100%">
    //   </mj-body>
    // </mjml>
    // `);


    seteditor(editor)
  }, []);

  function loadPage() {
    var newHtml =
      response.html != null ? editor.setComponents(response.html) : "";
    var newCss = response.css != null ? editor.setStyle(response.css) : "";
    // console.log("newHtml");
    // editor.setComponents(newHtml);
    // seteditor(editor);
  }

  const closeModal = () => {
    window.localStorage.setItem('modalEditor','true');
    setModalIsOpen(false);
  };

  function ModalEditor()
  {
    if(!window.localStorage.getItem('modalEditor') && modalIsOpen === true)
    {
      return (
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Reproductor de Video"
        style={{
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.7)', 
            zIndex: 1000, 
          },
          content: {
            width: '80%', 
            height: '80%', 
            margin: 'auto', 
            overflow: 'auto', 
          },
        }}
      >
        <button onClick={closeModal}>Cerrar</button>
        <ReactPlayer
          url="https://midas.li/media/videosfunnel/video3.mp4"
          controls={true}
          width="100%"
          height="100%"
          playing  ={true} 
        />
      </Modal>)
    }
    else {
      return "";
    }
  }

  return (
    <>
      <ModalEditor/>
      <div id="gjs"></div>
      <ToastContainer />
    </>
  );
}

export default Editor;
