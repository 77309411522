import React, { useState } from "react";
import DefaultButton from "../../components/buttons/DefaultButton";
import "./Home.css";
import logo from "../../assets/images/funnelLogo.png";
import { Link, useNavigate } from "react-router-dom";
import { LogOut, User, Menu } from "react-feather";
import { Popover } from "react-tiny-popover";
import Modal from 'react-modal';
import ReactPlayer from 'react-player';

const Home = () => {
  const [popover, setPopover] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(true);

  const username = window.localStorage.getItem("username");
  const [url, setUrl] = useState("");
  const navigate = useNavigate();

  // Agregar estado para controlar la visibilidad del menú móvil
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  // Función para abrir/cerrar el menú móvil
  const toggleMobileMenu = () => {
    setMobileMenuOpen(!mobileMenuOpen);
  };

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };
  return (
    <div className="home_full">
      <div className="header">
        <div className="logo">
          <img src={logo} alt="logo" />
        </div>
        <div className="nav">
          {/* Agregar el botón de menú hamburguesa */}
          <div className="mobile-menu-button" onClick={toggleMobileMenu}>
            <Menu />
          </div>
          <ul className={`list ${mobileMenuOpen ? "open" : ""}`}>
            <li>
              <Link to="/Home" className="nav-link">
                INICIO
              </Link>
            </li>
            <li>
              <Link to="/admin/dashboard" className="nav-link">
                FUNNELS
              </Link>
            </li>
            <li>
              <Link to="/GenerateResource" className="nav-link">
                GENERADOR DE IDEAS
              </Link>
            </li>
            <li>
              <Link
                onClick={() => {
                  window.localStorage.setItem("token_seguridad", "");
                }}
                to="/admin/login"
                className="nav-link"  
              >
                CERAR SESION
              </Link>
            </li>
          </ul>
          <Popover
            isOpen={popover}
            positions={["bottom", "top", "left", "right"]} // preferred positions by priority
            content={
              <div
                className="popover"
                onClick={() => {
                  navigate("/admin/login");
                  window.localStorage.setItem("token_seguridad", "");
                }}
              >
                Cerrar Sesión <LogOut />
              </div>
            }
          >
            <div
              className="avatar"
              onClick={() => {
                setPopover(!popover);
              }}
            >
              <User />
              {username}
            </div>
          </Popover>
        </div>
      </div>
      <div className="home">
        <h1>EMPIEZA EN EL MUNDO DE LAS VENTAS</h1>
        <div className="home_btn">
        <Link to="/admin/dashboard" className="btn_text">
          <DefaultButton
            textBtn={"FUNNELS "}  
            type="submit" 
            />
              </Link>
        </div>
        <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Reproductor de Video"
      >
        <button onClick={closeModal}>Cerrar</button>
        <ReactPlayer
          url="https://midas.li/media/videosfunnel/video2.mp4"
          controls={true}
          width="100%"
          height="100%"
          playing="true"
        />
      </Modal>
      </div>
    </div>
  );
};

export default Home;
