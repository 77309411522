import React, { useState, useEffect } from 'react';
import "./GenerateResource.css";
import config from "../../config.json";
import logo from "../../assets/images/funnelLogo.png";
import { Link, useNavigate } from "react-router-dom";
import { LogOut, User, Menu } from "react-feather";
import { Popover } from "react-tiny-popover";
import Modal from 'react-modal';
import ReactPlayer from 'react-player';

function GenerateResource() {
  const [message, setMessage] = useState('');
  const [messages, setMessages] = useState([]);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [popover, setPopover] = useState(false);
  const username = window.localStorage.getItem("username");
  const navigate = useNavigate();
  const [modalIsOpen, setModalIsOpen] = useState(true);

  const handleChange = (e) => {
    setMessage(e.target.value);
  };
  const toggleMobileMenu = () => {
    setMobileMenuOpen(!mobileMenuOpen);
  };
  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    window.localStorage.setItem('modalResource','true');
    setModalIsOpen(false);
  };


  const handleSubmit = async (e) => {
    e.preventDefault();

    const requestData = {
      text: message,
    };

    const onExitResource = (count) => {
      if (count === 3) {
        window.localStorage.setItem("view_resource", true);

      }
    };

    try {
      document.getElementById('loader').style.display = 'block';

      const responses = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestData),
      };

      const response = await fetch(config.requestURL + "/page/GenerateRecourse", responses);

      if (!response.ok) {
        throw new Error('La solicitud no fue exitosa');
      }

      const data = await response.json();
      setMessages((prevMessages) => [
        ...prevMessages,
        { content: message, role: 'user' },
      ]);
      
      setMessages((prevMessages) => [
        ...prevMessages,
        { content: data.text, role: 'assistant' },
      ]);
      
      setMessage('');
      document.getElementById('loader').style.display = 'none';
    } catch (error) {
      document.getElementById('loader').style.display = 'none';
      console.error('Error al enviar el mensaje:', error);
    }
  };

  useEffect(() => {
    ;
  }, [messages]);

  function ModalView()
  {
    if(!window.localStorage.getItem('modalResource') && modalIsOpen === true)
    {
      return (
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Reproductor de Video"
        style={{
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.7)', 
            zIndex: 1000, 
          },
          content: {
            width: '80%', 
            height: '80%', 
            margin: 'auto', 
            overflow: 'auto', 
          },
        }}
      >
        <button onClick={closeModal}>Cerrar</button>
        <ReactPlayer
          url="https://midas.li/media/videosfunnel/video4.mp4"
          controls={true}
          width="100%"
          height="100%"
          playing  ={true} 
        />
      </Modal>)
    }
    else {
      return "";
    }
  }

  return (
    <>
    <div className="header">
        <div className="logo">
          <img src={logo} alt="logo" />
        </div>
        <div className="nav">
          {/* Agregar el botón de menú hamburguesa */}
          <div className="mobile-menu-button" onClick={toggleMobileMenu}>
            <Menu />
          </div>
          <ul className={`list ${mobileMenuOpen ? "open" : ""}`}>
            <li>
              <Link to="/Home" className="nav-link">
                INICIO
              </Link>
            </li>
            <li>
              <Link to="/admin/dashboard" className="nav-link">
                FUNNELS
              </Link>
            </li>
            <li>
              <Link to="/GenerateResource" className="nav-link">
                GENERADOR DE IDEAS
              </Link>
            </li>
            <li>
              <Link
                onClick={() => {
                  window.localStorage.setItem("token_seguridad", "");
                }}
                to="/admin/login"
                className="nav-link"  
              >
                CERAR SESION
              </Link>
            </li>
          </ul>
          <Popover
            isOpen={popover}
            positions={["bottom", "top", "left", "right"]} // preferred positions by priority
            content={
              <div
                className="popover"
                onClick={() => {
                  navigate("/admin/login");
                  window.localStorage.setItem("token_seguridad", "");
                }}
              >
                Cerrar Sesión <LogOut />
              </div>
            }
          >
            <div
              className="avatar"
              onClick={() => {
                setPopover(!popover);
              }}
            >
              <User />
              {username}
            </div>
          </Popover>
        </div>
      </div>
      <h2 className='title' >GENERADOR DE IDEAS</h2>
      <ModalView/>
      <div id="loader" class="loader"></div>
      <div className="chat-container">
          {messages.map((msg, index) => (
            <div className="chat">
              <div key={index} className={`message_${msg.role}`}>
                {msg.content}
              </div>
            </div>
          ))}
        <form onSubmit={handleSubmit} className="input-form">
          <input
            type="text"
            value={message}
            onChange={handleChange}
            placeholder="Escribe un mensaje..."
            className="message-input"
          />
          <button type="submit" className="send-button">Enviar</button>
        </form>
      </div>
    </>
  );
}

export default GenerateResource;
